import React from 'react';
import Home from './components/pages/Home';

import './styles/App.scss';

function App() {
  return (
    <Home />
  );
}

export default App;
