import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Logo from '../../assets/logo.png';

import './styles/Header.scss';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const linkClose = () => {
    if (!menuOpen) return;

    setMenuOpen(false);
  };

  const handleClose = () => setMenuOpen(false);

  return (
    <Navbar key="lg" bg="light" expand="lg" className="mb-5" fixed="top">
      <Container>
        <Navbar.Brand>
          <img
            alt=""
            src={Logo}
            width="33"
            height="50"
          />
          {' '}
          <span className="d-none d-sm-inline">Santuário Luz dos Bichos</span>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="offcanvasNavbar-expand-lg"
          onClick={toggleMenu}
        />
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-lg"
          aria-labelledby="offcanvasNavbarLabel-expand-lg"
          placement="end"
          restoreFocus={false}
          show={menuOpen}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton />
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="#sobre" onClick={linkClose}>Quem somos</Nav.Link>
              <Nav.Link href="#adote" onClick={linkClose}>Adote</Nav.Link>
              <Nav.Link href="#ajude" onClick={linkClose}>Ajude</Nav.Link>
              <Nav.Link href="https://loja.luzdosbichos.com.br/">Nossa lojinha</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default Header;
