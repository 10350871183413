/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import CustomModal from '../templates/CustomModal';

function AdoptModal({ show, setShow }) {
  return (
    <CustomModal
      title="Como adotar"
      modalShow={show}
      setModalShow={setShow}
    >
      <Modal.Body>
        <p>
          Você pode solicitar sua participação no processo de adoção
          de um dos nossos pets entrando em contato pelo nosso Instagram.
          É só nos enviar uma mensagem que vamos responder rapidinho!
        </p>
        <p>
          Se você não tiver um Instagram, fale conosco pelo nosso&nbsp;
          <a href="https://www.facebook.com/luzdosbichos">
            Facebook
          </a>
            &nbsp;ou entre em contato pelo e-mail&nbsp;
          <a href="mailto:contato@luzdosbichos.com.br">
            contato@luzdosbichos.com.br
          </a>
          .
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => setShow(false)}>Cancelar</Button>
        <Button variant="primary" href="https://www.instagram.com/luzdosbichos/" target="_blank">Ir para o Instagram</Button>
      </Modal.Footer>
    </CustomModal>
  );
}

export default AdoptModal;
