import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';

function Help() {
  return (
    <Row className="mt-5 anchor justify-content-center" id="ajude">
      <Col xs={{ span: 12 }} className="py-lg-5 ps-lg-5 text-center">
        <h1 className="mb-4">
          Faça
          {' '}
          <span className="text-green">
            parte
          </span>
        </h1>
        <p>
          Envie sua doação e impacte positivamente não só a vida dos nossos animais, mas
          também promova conscientização por meio da informação. Se preferir, torne-se um
          voluntário no Santuário Luz dos Bichos e seja parte ativa da mudança na vida dos
          animais resgatados! Sua contribuição, seja financeira ou de tempo, é fundamental
          para o sucesso da nossa missão.
        </p>
        <Row className="justify-content-center">
          <Col xs={{ span: 12 }} lg={{ span: 4 }} className="text-xs-center">
            <form target="_blank" action="https://pagseguro.uol.com.br/checkout/v2/donation.html" method="post">
              <Button type="submit" variant="danger" size="lg" className="mt-4 text-white">
                Doe uma vez
              </Button>
              <input type="hidden" name="currency" value="BRL" />
              <input type="hidden" name="receiverEmail" value="luzdosbichos@gmail.com" />
              <input type="hidden" name="iot" value="button" />
            </form>
          </Col>
          <Col xs={{ span: 12 }} lg={{ span: 4 }} className="text-xs-center">
            <Button variant="secondary" size="lg" className="mt-4 text-black" target="_blank" href="https://doeonline.org/luzdosbichos/doe">
              Doação mensal
            </Button>
          </Col>
          <Col xs={{ span: 12 }} lg={{ span: 4 }} className="text-xs-center">
            <Button variant="warning" size="lg" className="mt-4 text-black" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScdv3lgJS_0p571p2NnmauVwDJ0V1DEaW4gdU-9gkLnZUWQSQ/viewform">
              Torne-se um voluntário
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Help;
