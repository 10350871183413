import React from 'react';
import { Container } from 'react-bootstrap';

import Header from '../organisms/Header';
import Featured from '../molecules/Featured';
import About from '../organisms/About';
import Adopt from '../organisms/Adopt';
import Media from '../organisms/Media';
import Help from '../organisms/Help';
import Footer from '../organisms/Footer';

function Home() {
  return (
    <main>
      <Header />
      <Container className="px-5 px-sm-4 px-xxl-0">
        <Featured />
        <About />
        <Adopt />
        <Media />
        <Help />
      </Container>
      <Footer />
    </main>
  );
}

export default Home;
