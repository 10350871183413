import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';

import Camara from '../../assets/media/camara.jpg';
import CidadeOn from '../../assets/media/cidadeon.png';
import G1 from '../../assets/media/g1.png';
import JornalPp from '../../assets/media/jornalpp.jpg';

// TODO: create card template
function Media() {
  return (
    <Row className="mt-5 anchor" id="midia">
      <Col xs={12} className="py-lg-5 ps-lg-5">
        <h1 className="mb-4">
          O santuário
          <span className="text-yellow"> na mídia</span>
        </h1>
        <Row xs={1} lg={4} className="g-3">
          <Col className="d-flex align-items-stretch">
            <Card className="w-100">
              <Card.Img variant="top" src={G1} />
              <Card.Body className="d-flex align-items-start flex-column">
                <Card.Title>Abandonos de animais crescem e adoções caem</Card.Title>
                <Card.Text>
                  Por conta da dificuldade de adoção, novos acolhimentos tiveram
                  que ser interrompidos no Santuário Luz dos Bichos...
                </Card.Text>
                <Card.Link className="mt-auto" href="https://g1.globo.com/sp/sao-carlos-regiao/noticia/2023/01/12/abandonos-de-animais-crescem-e-adocoes-caem-em-sao-carlos-saiba-como-adotar-e-ajudar-ongs.ghtml" target="_blank" rel="noreferrer">
                  Leia na íntegra &raquo;
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col className="d-flex align-items-stretch">
            <Card className="w-100">
              <Card.Img variant="top" src={CidadeOn} />
              <Card.Body className="d-flex align-items-start flex-column">
                <Card.Title>Cachorra resgatada desnutrida é adotada</Card.Title>
                <Card.Text>
                  A linda Nala conseguiu se recuperar com ajuda do Santuário Luz
                  dos Bichos...
                </Card.Text>
                <Card.Link className="mt-auto" href="https://www.acidadeon.com/saocarlos/cotidiano/Cachorra-resgatada-desnutrida-e-adotada-por-familia-em-Sao-Carlos-20221004-0012.html" target="_blank" rel="noreferrer">
                  Leia na íntegra &raquo;
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col className="d-flex align-items-stretch">
            <Card className="w-100">
              <Card.Img variant="top" src={JornalPp} />
              <Card.Body className="d-flex align-items-start flex-column">
                <Card.Title>Iguatemi São Carlos realiza feira de adoção animal</Card.Title>
                <Card.Text>
                  Evento acontecerá uma vez por mês no shopping e contará
                  com cachorros do Santuário Luz dos Bichos...
                </Card.Text>
                <Card.Link className="mt-auto" href="https://www.jornalpp.com.br/noticias/cidades/iguatemi-sao-carlos-realiza-feira-de-adocao-animal-neste-sabado-20/" target="_blank" rel="noreferrer">
                  Leia na íntegra &raquo;
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col className="d-flex align-items-stretch">
            <Card className="w-100">
              <Card.Img variant="top" src={Camara} />
              <Card.Body className="d-flex align-items-start flex-column">
                <Card.Title>Vereador Bruno Zancheta visita ONGs</Card.Title>
                <Card.Text>
                  Na última semana, o vereador Bruno Zancheta visitou o Santuário
                  Luz dos Bichos e outras duas ONGs...
                </Card.Text>
                <Card.Link className="mt-auto" href="https://camarasaocarlos.sp.gov.br/artigo/?a=noticia&id=8857" target="_blank" rel="noreferrer">
                  Leia na íntegra &raquo;
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Media;
