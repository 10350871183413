/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Button, Col, Image, Row,
} from 'react-bootstrap';

import Cat from '../../assets/cat.png';
import AdoptModal from '../molecules/AdoptModal';

function Adopt() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Row className="mt-5 anchor justify-content-center" id="adote">
        <Col xs={{ order: 2, span: 9 }} lg={{ order: 1, span: 4 }}>
          <Image src={Cat} fluid />
        </Col>
        <Col xs={{ order: 1, span: 12 }} lg={{ order: 2, span: 8 }} className="py-lg-5 ps-lg-5">
          <h1 className="mb-4">
            <span className="text-red">
              Adote
            </span>
          </h1>
          <p>
            É importante lembrar que adotar um animal é uma decisão responsável
            que requer consideração de tempo, espaço e recursos necessários para
            cuidá-lo. Se você está considerando adotar um, confira nossos animais
            disponíveis e entre em contato conosco para agendar uma entrevista.
            Juntos, podemos ajudar a proporcionar um lar amoroso para esses
            animais que tanto precisam.
          </p>
          <Button variant="primary" size="lg" className="mt-4 text-white" onClick={() => setShowModal(true)}>
            Saiba mais
          </Button>
        </Col>
      </Row>

      <AdoptModal show={showModal} setShow={setShowModal} />
    </>
  );
}

export default Adopt;
