/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from 'react-bootstrap';

function CustomModal({
  title,
  modalShow,
  setModalShow,
  children,
}) {
  return (
    <Modal
      show={modalShow}
      size="lg"
      onHide={() => setModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      { children }
    </Modal>
  );
}

export default CustomModal;
