import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import {
  Col, Container, Row, Stack,
} from 'react-bootstrap';

import './styles/Footer.scss';

function Footer() {
  const date = new Date();

  return (
    <footer className="page-footer font-small pt-4 mt-5">
      <Container className="text-md-left">
        <Row>
          <Col md="6" className="mt-md-0 mt-3">
            <h5 className="text-uppercase">Santuário Luz dos Bichos</h5>
            <p>
              Organização não governamental sem fins lucrativos
              <br />
              44.619.334/0001-13
              <br />
              São Carlos, SP, Brazil
            </p>
            <Stack direction="horizontal" gap={3} className="social-icons">
              <div>
                <a href="https://www.facebook.com/luzdosbichos/" target="_blank" rel="noreferrer" aria-label="Facebook">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/luzdosbichos/" target="_blank" rel="noreferrer" aria-label="Instagram">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </div>
              <div>
                <a href="https://www.tiktok.com/@luzdosbichos" target="_blank" rel="noreferrer" aria-label="TikTok">
                  <FontAwesomeIcon icon={faTiktok} />
                </a>
              </div>
            </Stack>
          </Col>

          <hr className="clearfix w-100 d-md-none my-4" />

          <Col md="3">
            <h5 className="text-uppercase">Links</h5>
            <ul className="list-unstyled">
              <li><a href="#sobre">Quem somos</a></li>
              <li><a href="#adote">Adote</a></li>
              <li><a href="#ajude">Faça parte</a></li>
              <li><a href="#midia">Na mídia</a></li>
              <li><a href="https://loja.luzdosbichos.com.br/">Nossa lojinha</a></li>
            </ul>
          </Col>

          <hr className="clearfix w-100 d-md-none my-4" />

          <Col md="3">
            <h5 className="text-uppercase">Parceiros</h5>
            <ul className="list-unstyled">
              <li><a href="https://ongasa.wordpress.com/" target="_blank" rel="noreferrer">ONG Asa</a></li>
              <li><a href="https://www.facebook.com/people/Cl%C3%ADnica-Veterin%C3%A1ria-Amig%C3%A3o/100052003104257/" target="_blank" rel="noreferrer">Clínica Amigão</a></li>
              <li><a href="https://www.facebook.com/farmaciacalendulavet/?locale=pt_BR" target="_blank" rel="noreferrer">Calêndula Vet</a></li>
              <li><a href="https://www.biotie.com.br/" target="_blank" rel="noreferrer">Biotiê</a></li>
              <li><a href="http://animalabsaocarlos.com.br/" target="_blank" rel="noreferrer">Animalab</a></li>
              <li><a href="http://www.kikaolanches.com.br/" target="_blank" rel="noreferrer">Kikão Lanches</a></li>
              <li><a href="https://www.agropecuariabrasil.com.br/" target="_blank" rel="noreferrer">Agropecuária Brasil</a></li>
              <li><a href="https://www.instagram.com/upburgerveg/" target="_blank" rel="noreferrer">Up!Burguer Veg</a></li>
            </ul>
          </Col>
        </Row>
      </Container>

      <div className="footer-copyright text-center p-3 mt-4">
        &copy;
        {' '}
        {date.getFullYear()}
        {' '}
        Santuário Luz dos Bichos - Todos os direitos reservados.
      </div>
    </footer>
  );
}

export default Footer;
