import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';

import Dogs from '../../assets/dogs.png';

function About() {
  return (
    <Row className="anchor" id="sobre">
      <Col>
        <h1 className="mb-4">
          Sobre o
          {' '}
          <span className="text-purple">santuário</span>
        </h1>
        <p>
          O Santuário Luz dos Bichos foi criado por veganos, vegetarianos
          e simpatizantes da causa que amam e respeitam os animais e o meio
          ambiente. A forma como vemos o mundo sustentável, possibilita
          transformar o cenário de maus tratos e a degradação da natureza
          através da educação e bons exemplos, unindo-os em um único proposito.
        </p>
        <Row className="justify-content-center">
          <Col xs="9" lg="6" className="m-4">
            <Image src={Dogs} className="md-auto" fluid />
          </Col>
        </Row>
        <p>
          Nos conhecemos através de resgates e cuidados de animais domésticos,
          mas hoje, nossa intenção é ir muito além e ajudar outras espécies de
          animais que também merecem nosso amor e respeito. Nosso foco é
          conscientizar as pessoas através da informação, trazendo um novo
          conceito de proteção animal e ambiental.
        </p>
      </Col>
    </Row>
  );
}

export default About;
