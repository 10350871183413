import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import FirstSlide from '../../assets/princesa.jpg';

function Featured() {
  return (
    <Carousel className="mb-5" indicators={false}>
      <Carousel.Item>
        <a href="#adote">
          <img
            className="d-block w-100"
            src={FirstSlide}
            alt="Adote a princesa"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  );
}

export default Featured;
